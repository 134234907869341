import React from 'react'
import useWidth from 'src/hooks/window/useWidth'

import Layout from 'src/layouts/BaseLayout'
import FormMobile from 'src/pages/nycfc-match/sections/form-mobile/_index'
import InterNYCFC from 'src/pages/nycfc-match/sections/inter-nycfc/_index'
import SoccerBackground from 'src/pages/nycfc-match/sections/soccer-background/_index'
import VoceFoiConvocado from 'src/pages/nycfc-match/sections/voce-foi-convocado/_index'
import { Wrapper } from 'src/pages/nycfc-match/style'

import pageContext from './pageContext.json'

const TheBankPT = () => {
  const WIDTH_MD = 768
  const windowWidth = useWidth(300)
  const isMobile = windowWidth < WIDTH_MD

  const lang = 'en'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <VoceFoiConvocado lang={lang} />
        {isMobile ? <FormMobile lang={lang} /> : null}
        <SoccerBackground lang={lang} />
        <InterNYCFC lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default TheBankPT
